<template lang="pug">
    div.main-container(id="container")
        div.overlay
        div.search-overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('adminhome.admindash')}}
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                                li.breadcrumb-item
                                    a(href="javascript:void(0);") {{ $t('headers.dashboard')}}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('headers.dashboard')}}

                div.row.mt-5
                    div.infobox-3.col-md-3.widget.bg-white
                        div.info-icon
                            svg.feather.feather-cpu(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                rect(x="4" y="4" width="16" height="16" rx="2" ry="2")
                                rect(x="9" y="9" width="6" height="6")
                                line(x1="9" y1="1" x2="9" y2="4")
                                line(x1="15" y1="1" x2="15" y2="4")
                                line(x1="9" y1="20" x2="9" y2="23")
                                line(x1="15" y1="20" x2="15" y2="23")
                                line(x1="20" y1="9" x2="23" y2="9")
                                line(x1="20" y1="14" x2="23" y2="14")
                                line(x1="1" y1="9" x2="4" y2="9")
                                line(x1="1" y1="14" x2="4" y2="14")
                        h5.info-heading {{ $t('adminhome.mycenters')}}
                        p.info-text {{ $t('adminhome.managercentre')}}
                        router-link.info-link(to="/pages/dashboardCenter") {{ $t('btns.btnwatch')}}
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12")
                                polyline(points="12 5 19 12 12 19")
                                  
                                    
                    div.infobox-3.col-md-3.widget.bg-white
                        div.info-icon
                            svg.feather.feather-clipboard(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                path(d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2")
                                rect(x="8" y="2" width="8" height="4" rx="1" ry="1")
                        h5.info-heading {{ $t('adminhome.myinventor')}}
                        p.info-text {{ $t('adminhome.consultinv')}}
                        router-link.info-link(to="/pages/myCentersInventory") {{ $t('btns.btnwatch')}} 
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12") 
                                polyline(points="12 5 19 12 12 19")
                                    
                                    
                    div.infobox-3.col-md-3.bg-white
                        div.info-icon
                            svg.feather.feather-package(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="16.5" y1="9.4" x2="7.5" y2="4.21")
                                path(d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z")
                                polyline(points="3.27 6.96 12 12.01 20.73 6.96")
                                line(x1="12" y1="22.08" x2="12" y2="12")
                        h5.info-heading {{ $t('adminhome.myrequest')}}
                        p.info-text {{ $t('adminhome.checkstatus')}}
                        router-link.info-link(to="/pages/myInventoryRequest") {{ $t('btns.btnwatch')}}  
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12")
                                polyline(points="12 5 19 12 12 19")

                                    
                    div.infobox-3.col-md-3.widget.bg-white
                        div.info-icon
                            svg.bi.bi-ui-checks-grid(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                path(d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z")
                        h5.info-heading {{ $t('adminhome.codeware')}}
                        p.info-text {{ $t('adminhome.managercode')}}
                        router-link.info-link(to="/pages/CodesStore") {{ $t('btns.btnwatch')}} 
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12")
                                polyline(points="12 5 19 12 12 19")


                    div.infobox-3.col-md-3.widget.bg-white
                        div.info-icon
                            svg.feather.feather-award(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                circle(cx="12" cy="8" r="7")
                                polyline(points="8.21 13.89 7 23 12 20 17 23 15.79 13.88")
                        h5.info-heading {{ $t('adminhome.privileges')}}
                        p.info-text {{ $t('adminhome.manegeuse')}}
                        router-link.info-link(to="/pages/AdminAssign") {{ $t('btns.btnwatch')}} 
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12")
                                polyline(points="12 5 19 12 12 19")


                    div.infobox-3.col-md-3.widget.bg-white 
                        div.info-icon
                            svg.bi.bi-patch-check(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                path(fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z")
                                path(d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z")
                        h5.info-heading {{ $t('adminhome.assigcodegrup')}}
                        p.info-text {{ $t('adminhome.assiginvcod')}}
                        router-link.info-link(to="/pages/AttendGroupInventory") {{ $t('btns.btnwatch')}}
                            svg.feather.feather-arrow-right(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                line(x1="5" y1="12" x2="19" y2="12")
                                polyline(points="12 5 19 12 12 19")

                div.footer-wrapper
                    div.footer-section.f-section-1
                        p © 2021 
                            a(target="_blank" href="https://etciberoamerica.com/") ETC Iberoamérica
                            template , {{ $t('adminhome.copy')}}
                    div.footer-section.f-section-2
                        p

</template>

<script>
/* eslint-disable */
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
        //this.checkPermisions(this.$router.currentRoute.path)
    }
}
</script>
